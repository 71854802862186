import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { Layout } from '../../../../components/layout'
import { useEventReports } from '../../../../hooks/useEventReports'
import { useCategories } from '../../../../hooks/useCategories'
import { EventReports } from '../../../../components/event-reports'
import { Article } from '../../../../components/article'
import { EventReport as EventReportType } from '../../../../types/event-report'
import { Category as CategoryType } from '../../../../types/category'
import { SEO } from '../../../../components/seo'

const EventReportPage = ({ params }: any) => {
	const { eventReports } = useEventReports()
	const [eventReport, setEventReport] = useState<EventReportType | null>(null)
	const { categories } = useCategories()
	const [category, setCategory] = useState<CategoryType | null>(null)

	useEffect(() => {
		const eventReport = eventReports.find(eventReport => eventReport.title === params._title)
		if (eventReport) {
			setEventReport(eventReport)
		}
	}, [params, eventReports])

	useEffect(() => {
		const category = categories.find(category => category.title === params._category)
		if (category) {
			setCategory(category)
		}
	}, [params, categories])

	return (
		<Layout>
			<SEO
				title={`${params._title} > ${params._category} | RUBIA ルビア 渋谷メキシカン【朝食・ランチ・バー】`}
				description={''}
				keywords={['メキシコ料理', '渋谷' , 'ファインメキシカン', 'ルビア', 'sarasa']}
			/>
			<h1 className="h1">{params._title} | RUBIA ルビア / DJ sarasa</h1>
			<div className="story">
				{eventReport && category && (
					<>
						<Article type="eventReport" article={eventReport} category={category} />
						<EventReports />
					</>
				)}
			</div>
		</Layout>
	)
}

export default EventReportPage
