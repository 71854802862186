import React from "react";
import { Link } from "gatsby";
import { format } from "date-fns";
import Slider from "react-slick";
import { ScrollAndShow } from "../components/scroll-and-show";
import { useEventReports } from "../hooks/useEventReports";

export const EventReports = () => {
	const { eventReports } = useEventReports();

	return (
		<ScrollAndShow delay={200}>
			<div className="md:py-16 py-8">
				<div className="event-report-text font-lato text-center md:mb-10 mb-5">
					<Link to="/event_reports">EVENT</Link>
				</div>
				<div className="slider-wrapper">
					<Slider
						{...{
							dots: false,
							arrows: false,
							infinite: true,
							autoplay: true,
							autoplaySpeed: 3000,
							speed: 500,
							slidesToShow: 1,
							slidesToScroll: 1,
							variableWidth: true,
						}}
						className="md:pl-11 pl-3.5"
					>
						{eventReports.map((eventReport, index) => (
							<Link key={index} to={`/event_reports/${eventReport.title}`}>
								<div className="md:mr-10 mr-3.5">
									<img
										className="story-image"
										src={eventReport.coverImage.url}
										alt={eventReport.title}
									/>
									<div className="title font-yu">{eventReport.title}</div>
									<div className="font-lato vol">
										{format(new Date(eventReport.date), "yyyy.MM.dd")}
									</div>
								</div>
							</Link>
						))}
					</Slider>
				</div>
			</div>
		</ScrollAndShow>
	);
};
